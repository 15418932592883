<template>
  <div class="relative">
    <v-icon
      v-if="image.url || image.imgDataUrl"
      class="upload-file__delete cursor-pointer"
      @click="$emit('deleteFile')"
      color="red"
      size="18px"
      >mdi-close</v-icon
    >
    <div
      class="upload-file rounded-circle d-flex align-center justify-center text-center cursor-pointer w-100 h-100"
      :style="{ borderColor: borderColor }"
      :class="mouseOn ? 'upload-file--on' : ''"
      @click="handleClick"
    >
      <div v-if="!image.url && !image64">
        <!-- <img
        :src="require('@/assets/images/icons/plus.svg')"
        alt=""
      /> -->
      </div>
      <img
        v-if="image.url || image64"
        class="object-cover"
        width="100%"
        height="100%"
        :src="cropImage ? image64 : image.url"
        alt=""
      />
      <img
        v-else
        :src="require('@/assets/images/icons/img-placeholder.svg')"
        alt="image placeholder"
        class="img-placeholder"
      />

      <v-file-input
        v-if="!cropImage"
        hide-input
        @change="$emit('change', $event)"
      ></v-file-input>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {},
    text: {
      type: String,
      default: "",
    },
    borderColor: {
      type: String,
      default: "",
    },
    image64: {
      type: String,
      default: "",
    },
    cropImage: {
      type: Boolean,
      default: false,
    },
    mouseOn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  methods: {
    handleClick() {
      if (this.cropImage) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style lang="scss">
.upload-file {
  border: 1px solid $primary;
  background: $greenbg;
  position: relative;
  transition: 0.3s;
  overflow: hidden;

  .v-input__icon,
  .v-input__prepend-outer,
  .v-input button,
  .v-input {
    padding: 0px;
    margin: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
  }

  &__delete {
    position: absolute !important;
    top: 2px;
    right: 2px;
    z-index: 99;
  }
}
</style>
