<template>
  <div class="new-item">
    <div class="max-width mx-auto">
      <div class="new-item__content">
        <!-- desktop head -->

        <div class="w-100 mb-3">
          <div
            class="max-width w-100"
            :class="isMobile ? 'text-center' : ' text-left'"
          >
            <span class="black--text font-24 font-500">New Template</span>
          </div>
        </div>
        <v-form v-model="valid" ref="templateForm">
          <v-row>
            <!-- content -->
            <v-col cols="12">
              <div class="d-flex">
                <div
                  v-for="(image, i) in images"
                  :key="i"
                  class="px-0 pr-0 pb-0"
                >
                  <div class="pa-1">
                    <upload-btn
                      class="upload-logo"
                      @click="uploadImageHandler(image, i)"
                      @deleteFile="deleteFile(i)"
                      text="Logo image"
                      :image64="image.imgDataUrl"
                      :image="image"
                      :cropImage="true"
                    ></upload-btn>
                  </div>
                </div>
              </div>

              <div class="pt-0">
                <span class="font-18 d-block black--text mt-2"
                  >Template Image</span
                >
                <span class="font-15 d-block greytext--text"
                  >Max 5 Pic <span class="font-600">(JPG, PNG*)</span></span
                >
              </div>
            </v-col>

            <!-- title -->
            <v-col cols="12" md="5" sm="7">
              <v-text-field
                v-model="template.title"
                label="Template Title"
                dense
                :hide-details="true"
              ></v-text-field>
            </v-col>

            <!-- space -->
            <v-col cols="12" class="ma-0 pa-0 py-0"></v-col>

            <!-- price -->
            <v-col cols="12" md="6" sm="8">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="template.oldPrice"
                    label="Price*"
                    dense
                    outlined
                    :hide-details="true"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="template.price"
                    label="New Price*"
                    dense
                    outlined
                    :hide-details="true"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <!-- space -->
            <v-col cols="12" class="ma-0 pa-0 py-0"></v-col>

            <!-- banner -->
            <v-col cols="12" md="5" sm="7">
              <span class="font-600 black--text d-block">Banner:</span>
              <span class="grey--text d-block font-12"
                >add your custome from
                <span
                  @click="bannerValue = 'Custom Banner'"
                  class="font-600 cursor-pointer"
                  >here</span
                ></span
              >
              <v-radio-group class="mt-2" v-model="bannerValue">
                <v-radio label="Special Offer" value="Special Offer"></v-radio>
                <v-radio label="Save" value="save"></v-radio>
                <v-radio label="Custom Banner" value="Custom Banner"></v-radio>
                <div class="px-4">
                  <v-text-field
                    v-if="bannerValue == 'Custom Banner'"
                    v-model="template.bannerText"
                    label="Write Text"
                    dense
                    :hide-details="true"
                  ></v-text-field>
                </div>
              </v-radio-group>
            </v-col>

            <!-- space -->
            <v-col cols="12" class="ma-0 pa-0"></v-col>

            <!-- description -->
            <v-col cols="12" md="5" sm="7">
              <v-textarea
                v-model="template.description"
                label="Template Description"
                dense
                rows="3"
                :hide-details="true"
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-btn
                width="130px"
                height="40px"
                color="primary"
                class="radius-10"
                @click="addItem"
              >
                <span class="font-18 font-500 white--text">Done</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>

    <v-dialog
      v-model="uploadImage"
      content-class="no-shadow white-bg radius-15 relative upload-dialog"
    >
      <my-upload
        field="img"
        @crop-success="cropSuccess"
        :width="300"
        :height="300"
        img-format="png"
        langType="en"
        :noCircle="true"
        :key="uploadImage"
      ></my-upload>
      <img
        width="15px"
        height="15px"
        :src="require('@/assets/images/icons/close.svg')"
        class="upload-cancel cursor-pointer"
        @click="uploadImage = false"
      />
    </v-dialog>
  </div>
</template>

<script>
// import LongText from "../../components/base/LongText.vue";
import UploadBtn from "../../components/base/UploadBtn.vue";
import myUpload from "vue-image-crop-upload";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    itemID: {
      type: Object,
      default: Object,
    },
  },
  components: { UploadBtn, "my-upload": myUpload },
  data: () => ({
    valid: false,
    file: {},
    fileKey: false,
    template: {
      images: [
        { image: {} },
        { image: {} },
        { image: {} },
        { image: {} },
        { image: {} },
      ],
    },
    text: "Template Description",

    uploadImage: false,
    imgDataUrl: {},
    image: {},

    bannerValue: "Special Offer",
    bannerText: "Special Offer",
    images: [
      { image: {}, imgDataUrl: "" },
      { image: {}, imgDataUrl: "" },
      { image: {}, imgDataUrl: "" },
      { image: {}, imgDataUrl: "" },
      { image: {}, imgDataUrl: "" },
    ],
    selectedIndex: 0,
  }),
  computed: {},
  methods: {
    deleteFile(i) {
      this.images[i].imgDataUrl = "";
      this.fileKey = !this.fileKey;
    },
    async addItem() {
      let formData = new FormData();
      formData.append("title", this.template.title);
      formData.append("description", this.template.description);
      formData.append("type", "template");
      formData.append("price", this.template.price);
      formData.append("oldPrice", this.template.oldPrice);
      formData.append("active", true);
      formData.append("featured", true);
      formData.append("banner", this.bannerValue);
      formData.append("text", this.bannerText);

      this.template.images.forEach((image) => {
        if (image.image.file) {
          formData.append("images", image.image.file);
        }
      });
      // check validate
      let valid = this.$refs.templateForm.validate();
      if (!valid) return;
      let res;
      if (this.paramID) {
        res = await this.$axios.put(
          `${process.env.VUE_APP_PRODUCTS}/${this.template.id}`,
          formData
        );
      } else {
        res = await this.$axios.post(process.env.VUE_APP_PRODUCTS, formData);
      }
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: "Added Successfully",
          color: "success",
        });
        this.$router.replace("/templates");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    uploadImageHandler(_, i) {
      this.uploadImage = true;
      this.selectedIndex = i;
    },
    async cropSuccess(imgDataUrl) {
      this.images[this.selectedIndex].imgDataUrl = imgDataUrl;
      this.uploadImage = false;
      this.template.images[this.selectedIndex].image.file = this.dataURLtoFile(
        imgDataUrl,
        "image.png"
      );
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    // async getCurrency() {
    //   let { data } = await this.$axios.get("currencies");
    //   if (data.success) {
    //     this.currencies = data.currencies;
    //   }
    // },
  },
  async created() {},
};
</script>

<style lang="scss">
.new-item {
  .max-width {
    max-width: 900px;
  }
  .upload-logo {
    height: 100px;
    width: 100px !important;
  }

  &__preview {
    background: #f0f0f0;
    border-radius: 25px;
    height: 320px;
    max-height: 320px;
    width: 320px;
    height: max-content;
    padding: 23px 38px;
  }
  &__price {
    border-left: 1px solid #cecccc;
    padding-left: 20px;
    &__discount {
      position: relative;
      width: max-content;
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 1px;
        transform: rotate(21deg);
        background: red;
        top: 50%;
      }
    }
  }
  .v-list {
    background: white;
  }
  @media (max-width: 960px) {
    &__content {
      padding: 0px 40px;
    }
  }
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #c3c3c3;
}
</style>
